/** @jsx jsx */

import { jsx, Spinner, Text, Message } from "theme-ui";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

import { get, noop } from "sites-common/utils/lodash";
import { containerStyles } from "sites-common/utils/fabricStyles";
import useMyBoolean from "sites-common/hooks/useMyBoolean";
import {
  ActionButton,
  DefaultButton,
  Stack,
  StackItem,
} from "office-ui-fabric-react";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import PropTypes from "prop-types";
import copyKeys from "sites-common/utils/copyKeys";
import ConfirmButton from "gatsby-plugin-hfn-profile/components/ConfirmButton";
import DownloadRecordsButton from "../../../components/Trainer/AbhyasiMentoring/admin";

import DLWLayout from "../../../layouts/dlw-fixed";

const colors = {
  shade1: "#ffe755",
  shade2: "#871282",
  shade3: "#f9ff97",
  shade4: "#ffa535",
  shade5: "#ffebad",
};

///
/// config
///

const { tables: listTables } =
  require("../../../components/Trainer/tables").default;

///
/// Regular DB Rendering with CRUD
///

const submitButton = <DefaultButton type="submit" text="🔎 Search" />;

const RenderRecord = ({ a, tableRec, counter }) => {
  const { collection, formFields } = tableRec;
  const firestore = useFirestore();

  const [editMode, { setTrue: setEditMode, setFalse: clearEditMode }] =
    useMyBoolean(false);

  const onSubmit = useCallback(
    (data, sCb, fCb) => {
      const fields = copyKeys(
        data,
        formFields.map((f) => f.name)
      );
      firestore
        .set(`${collection}/${a.id}`, fields, { merge: true })
        .then(() => {
          sCb();
        })
        .catch((e) => {
          fCb(e.toString());
        });
    },
    [a.id, firestore, collection, formFields]
  );

  const onDelete = useCallback(() => {
    firestore
      .delete(`${collection}/${a.id}`)
      .then(() => {})
      .catch((/* e */) => {
        // console.error(e.toString());
      });
  }, [a.id, firestore, collection]);

  if (editMode) {
    return (
      <Message>
        <DynamicForm
          formFields={formFields}
          defaultValues={a}
          onSubmit={onSubmit}
          onDiscard={clearEditMode}
        />
        <Text sx={{ color: colors.shade2 }}>{counter}</Text>
      </Message>
    );
  }

  return (
    <Message sx={{ bg: colors.shade1 }}>
      <Stack horizontal horizontalAlign="space-between">
        <StackItem>
          <DynamicForm formFields={formFields} defaultValues={a} printOnly />
        </StackItem>
        <Stack>
          <ActionButton
            text="edit"
            iconProps={{ iconName: "Edit" }}
            onClick={setEditMode}
          />
          <ConfirmButton
            title="Deleting Record"
            message="Are You Sure?"
            onClick={onDelete}
          >
            <ActionButton text="delete" iconProps={{ iconName: "Trash" }} />
          </ConfirmButton>
        </Stack>
      </Stack>
      <Text sx={{ color: colors.shade2 }}>{counter}</Text>
    </Message>
  );
};

RenderRecord.propTypes = {
  a: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  tableRec: PropTypes.shape({
    collection: PropTypes.string,
    formFields: PropTypes.shape([]),
  }).isRequired,
  counter: PropTypes.number,
};
RenderRecord.defaultProps = {
  counter: undefined,
};

const NewRecord = ({ onDiscard, tableRec }) => {
  const { collection, formFields, defaultValues = {} } = tableRec;
  const firestore = useFirestore();

  const onSubmit = useCallback(
    (data, sCb, fCb) => {
      const fields = copyKeys(
        data,
        formFields.map((f) => f.name)
      );
      firestore
        .add(`${collection}`, fields)
        .then(() => {
          sCb();
        })
        .catch((e) => {
          fCb(e.toString());
        });
    },
    [firestore, collection, formFields]
  );

  return (
    <Message>
      <DynamicForm
        formFields={formFields}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
      />
    </Message>
  );
};

NewRecord.propTypes = {
  onDiscard: PropTypes.func,
  tableRec: PropTypes.shape({
    collection: PropTypes.string.isRequired,
    formFields: PropTypes.shape([]),
    defaultValues: PropTypes.shape({}),
  }).isRequired,
};
NewRecord.defaultProps = {
  onDiscard: noop,
};
function RenderDB({ tableRec }) {
  const {
    collection,
    name: storeAs,
    initialLimit = 20,
    where,
    equals,
  } = tableRec;

  const firestore = useFirestore();
  const allrecords1 = useSelector((state) =>
    get(state.firestore.ordered, [storeAs], undefined)
  );
  const [add, { setTrue: setAdd, setFalse: clearAdd }] = useMyBoolean(false);

  const [showrecs] = useMyBoolean(true);

  const loading = allrecords1 === undefined;
  const allrecords = allrecords1 || [];

  const [limit, setLimit] = useState(initialLimit);

  const loadMore = useCallback(() => {
    setLimit(
      (x) => x + (!initialLimit || initialLimit > 20 ? 20 : initialLimit)
    );
  }, [initialLimit]);

  useEffect(() => {
    const listenerSettings = {
      collection,
      storeAs,
      limit,
      ...(where && equals ? { where: [where, "==", equals] } : {}),
    };
    firestore.setListener(listenerSettings);
    return function cleanup() {
      firestore.unsetListener(listenerSettings);
    };
  }, [firestore, collection, storeAs, limit, equals, where]);

  const X1 = (
    <div sx={{ bg: colors.shade3, p: 3, color: colors.shade4 }}>
      {" "}
      Showing <b>{allrecords.length}</b> records{" "}
      {!!limit && limit === allrecords.length && (
        <ActionButton text="↳ show more" onClick={loadMore} />
      )}
      {add && <NewRecord onDiscard={clearAdd} tableRec={tableRec} />}
    </div>
  );
  return (
    <div>
      {loading && (
        <div>
          {" "}
          <Spinner />
        </div>
      )}
      {!loading && (
        <div sx={{ m: 2 }}>
          {X1}
          {showrecs &&
            allrecords.map((a, c) => {
              return (
                <div sx={{ my: 3 }}>
                  <RenderRecord
                    counter={c + 1}
                    a={a}
                    key={a.id}
                    tableRec={tableRec}
                  />
                </div>
              );
            })}
          {X1}
          {!add && (
            <DefaultButton
              sx={{ mt: 4 }}
              iconProps={{ iconName: "Add" }}
              text="Add New Record"
              onClick={setAdd}
            />
          )}
        </div>
      )}
    </div>
  );
}
RenderDB.propTypes = {
  tableRec: PropTypes.shape({
    collection: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    initialLimit: PropTypes.number,
    where: PropTypes.string,
    equals: PropTypes.string,
  }).isRequired,
};

const flds = [
  { name: "table", label: "Table", type: "select", options: listTables },
  {
    name: "where",
    label: "Filter On",
    type: "select",
    options: [],
    props: { required: false },
  },
  { name: "equals", label: "Equals", type: "text", props: { required: false } },
];

function FixFirestore() {
  const [tableRec, setTableRec] = useState(listTables[0]);

  const onChangeTable = useCallback(
    (values, sCb) => {
      const n = values.table;
      const nrec = listTables.find((r) => r.name === n);
      setTableRec({ ...nrec, ...values });
      sCb();
    },
    [setTableRec]
  );

  return (
    <DLWLayout sx={{ bg: "white", pb: 4 }}>
      <Text style={containerStyles.flexcenter} variant="header">
        {tableRec.label}
      </Text>
      <Text style={containerStyles.flexcenter} variant="subtitle">
        Admin Console
      </Text>
      <div sx={{ bg: colors.shade5, p: 2, m: 2 }}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 10, padding: 10 }}
        >
          <StackItem>
            <DynamicForm
              formFields={flds.map((x) =>
                x.name === "where" ? { ...x, options: tableRec.formFields } : x
              )}
              defaultValues={{ table: tableRec.name }}
              onSubmit={onChangeTable}
              hideDiscard
              buttons={{ submitButton }}
            />
          </StackItem>
          <StackItem>
            <DownloadRecordsButton
              dbcode="mentoring"
              fields="mentor_country,mentor_state,mentor_zone,mentor_city,mentor_center,mentor_abhyasi_id,mentor_name,mentee_abhyasi_id,mentee_name,mentee_gender,mentee_age,mentee_center,mentee_city,mentee_zone,mentee_state,mentee_country,date_added,notes"
            />
          </StackItem>
        </Stack>
      </div>
      <RenderDB
        tableRec={tableRec}
        key={tableRec.name + tableRec.where + tableRec.equals}
      />
    </DLWLayout>
  );
}

export default FixFirestore;
