const tables = [
  {
    name: "am1",
    label: "Abhyasis Mentoring",
    collection: "AbhyasiMentoring",
    formFields: [
      { name: "mentor_country", label: "Preceptor Country" },
      { name: "mentor_state", label: "Preceptor State" },
      { name: "mentor_zone", label: "Preceptor Zone" },
      { name: "mentor_city", label: "Preceptor City" },
      { name: "mentor_center", label: "Preceptor Center" },
      { name: "mentor_abhyasi_id", label: "Preceptor Id" },
      { name: "mentor_name", label: "Preceptor Name" },
      { name: "mentee_abhyasi_id", label: "Mentee Id" },
      { name: "mentee_name", label: "Name" },
      { name: "mentee_gender", label: "Gender" },
      { name: "mentee_age", label: "Age" },
      { name: "mentee_center", label: "Center" },
      { name: "mentee_city", label: "City" },
      { name: "mentee_zone", label: "Zone" },
      { name: "mentee_state", label: "State" },
      { name: "mentee_country", label: "Country" },
      { name: "notes", label: "Notes" },
      { name: "date_added", label: "Date Added" },
    ],
  },
  //   {
  //     name: "pss2i",
  //     label: "Special Sittings Journal",
  //     collection: "PreceptorSpecialSittings2022Index",
  //     formFields: [
  //       { name: "date_added", label: "date_added" },
  //       { name: "mentor_name", label: "mentor_name" },
  //       { name: "mentor_center", label: "mentor_center" },
  //       { name: "mentor_zone", label: "mentor_zone" },
  //     ],
  //   },
];

const T = { tables };

export default T;
