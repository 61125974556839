/** @jsx jsx */
import { DefaultButton } from "office-ui-fabric-react";
import getMandatoryEnv from "sites-common/utils/getMandatoryEnv";
import { jsx } from "theme-ui";
import PropTypes from "prop-types";

const { cloudFunctions } = getMandatoryEnv(["cloudFunctions"]);

function Records({ btnText, dbcode, fields, ...props }) {
  return (
    <DefaultButton
      target="_blank"
      text={btnText}
      {...props}
      href={`${cloudFunctions.csvdump}${dbcode}?fields=${fields}`}
    />
  );
}
Records.propTypes = {
  btnText: PropTypes.string,
  dbcode: PropTypes.string.isRequired,
  fields: PropTypes.shape({}).isRequired,
};

Records.defaultProps = {
  btnText: "Download All Records",
};

export default Records;
